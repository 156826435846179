import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

enum BREAKPOINTS {
  mobile = 0,
  tablet = 769,
  desktop = 1024,
  widescreen = 1216,
  fullhd = 1408
}

export default new Vuex.Store({
  state: {
    darkMode: false,
    windowWidth: 0,
    windowHeight: 0,
    LOCALES: {'en-GB': 'English', 'de-DE': 'Deutsch', 'fr-FR': 'Français', 'es-ES': "Español", 'it-IT': 'Italiano', 'tr-TR': 'Türkçe', 'pt-PT': 'Português', 'zh-CN': '中文', 'hi-IN': 'हिन्दी', 'ru-RU': 'Русский'},
    BREAKPOINTS: BREAKPOINTS,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
