import _Vue from 'vue';

export function setSessionCookie(cname: string, cvalue: string) {
  document.cookie = cname + "=" + encodeURIComponent(JSON.stringify(cvalue)) + ";path=/;SameSite=None;Secure";//;domain=loca.lt";
}

export function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + encodeURIComponent(JSON.stringify(cvalue)) + ";" + expires + ";path=/;SameSite=None;Secure";//;domain=loca.lt";
}

export function getCookie(cname: string) {
  const name = cname + "=";
  const ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return JSON.parse(decodeURIComponent(c.substring(name.length, c.length)));
    }
  }
  return "";
}

_Vue.prototype.$setTimedCookie = function (cname: string, cvalue: string, expiry: number) {
  const d = new Date(expiry);
  const expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + encodeURIComponent(JSON.stringify(cvalue)) + ";" + expires + ";path=/;SameSite=None;Secure";//;domain=loca.lt";
}

_Vue.prototype.$setCookie = function (cname: string, cvalue: string, exdays: number) {
  setCookie(cname, cvalue, exdays)
}

_Vue.prototype.$setSessionCookie = function (cname: string, cvalue: string) {
  setSessionCookie(cname, cvalue);
}

_Vue.prototype.$renewCookie = function (cname: string, exdays: number) {
  const value = _Vue.prototype.$getCookie(cname);
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + encodeURIComponent(JSON.stringify(value)) + ";" + expires + ";path=/;SameSite=None;Secure";//;domain=loca.lt";
}

_Vue.prototype.$deleteCookie = function (cname: string) {
  const d = new Date(0);
  const expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + encodeURIComponent(JSON.stringify(null)) + ";" + expires + ";path=/;SameSite=None;Secure";//;domain=loca.lt";
}

_Vue.prototype.$getCookie = function (cname: string) {
  return getCookie(cname);
}
