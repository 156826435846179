<template>
    <section class="modal-card-body">
        <h1 class="title mb-5">{{ $t('chooseYourLanguage') }}</h1>
        <div v-for="i in [...Array(numRows).keys()]" :key="i" class="columns is-mobile">
            <div class="column"
                v-for="[locale, language] of Object.entries(locales).slice(i * numColumns, (i + 1) * numColumns)"
                :key="language" :value="locale">
                <span class="custom-hover-primary is-clickable has-text-weight-bold" v-on:click="selected(locale)">
                    {{ language }}
                </span>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            numColumns: this.$store.state.windowWidth >= this.$store.state.BREAKPOINTS.desktop ? 5 : 2.
        }
    },
    computed: {
        locales() {
            return this.$store.state.LOCALES;
        },
        numRows() {
            return Math.round(Object.keys(this.locales).length / this.numColumns);
        },
    },
    methods: {
        selected(locale) {
            this.$i18n.locale = locale;
            this.$setCookie("selectedLocale", locale, 365);
            this.$emit("close");
        }
    }
}
</script>