// Auto generated constants
export default {PAGE_NAME: "Servisim",
FORM_CONSTANTS:{
    MIN_PASSWORD_LENGTH: 8,
    MIN_NAME_LENGTH: 3,
    MAX_NAME_LENGTH: 48,
    MAX_EMAIL_ADDRESS_LENGTH: 128,
    MIN_MESSAGE_LENGTH_IF_REQUIRED: 5,
    MAX_MESSAGE_LENGTH: 2000,
    LAWS: ["GDPR", "CCPA", "CPA", "CTDPA", "UCPA", "VCDPA", "OTHER"],
    PURPOSES: ["knowWhatInfoIsCollected", "deleteMyInfo", "optOutSellToThirdParties", "optInSellMyData", "accessMyInfo", "correctInfo", "receiveACopyOfMyInfo", "optOutCrossContextAdvertising", "limitTheUseOfMyInfo", "otherPleaseSpecifyInTheCommentBoxBelow"],
    CONFIRMATIONS: ["confirmInformationIsCorrect", "confirmUnderstandDeletionIsIrreversible", "confirmUnderstandValidationRequired"]}}